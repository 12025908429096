import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import "./styles.scss"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ModalButton from "../components/modal"
import CourseCard from "../components/courseCard"
import CourseForm from "../components/courseForm"

const Courses = ({data}) => {
    
    return (
      <Layout>
        <SEO title="Home" />
        <section class="hero is-small">
        <div class="hero-body">
          <div class="container has-text-centered">
            <h1 class="title">
              Folgende Nähkurse biete ich an:
      </h1>
            <h2 class="subtitle">
              Falls für dich nicht das richtige dabei ist oder  <br />  du für 
              eine Gruppe anfragen möchtest, schreibe mir gerne eine Nachricht.
      </h2>
      <Link to="/kontakt/" class="button is-primary" state={{ typeRequest: "allgemein" }}>Anfragen</Link>

          </div>
        </div>
      </section>
        <section className="section">
          <div className="kurse_home">
            <div className="columns">
              {data.allMarkdownRemark.edges.map(({node}) => (
                <div className="column">
                <CourseCard   
                  title={node.frontmatter.title} 
                  html_content={node.html}
                  price={node.frontmatter.price}
                  duration={node.frontmatter.duration}
                  date={node.frontmatter.date}
                  image={
                    <Img
                      fluid={node.frontmatter.courseImage.childImageSharp.fluid}
                      alt="bla" />
                    }
                  />
              </div>
              )) }
            </div>
          </div>
        </section>
      </Layout>
    );
  
}

export const query = graphql`
query {
  allMarkdownRemark(filter: {frontmatter: {category: {eq: "kurs"}}}, sort: {order: ASC, fields: frontmatter___id}) {
    edges {
      node {
        html
        frontmatter {
          id
          title
          level
          price
          duration
          date
          courseImage {
            ...squareImage
          } 
        }
      }
    }
  }
}
  `

export default Courses
