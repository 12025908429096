import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const CourseCard = (props) => {
    return (
        <div class="card bm--card-equal-height">
            <div class="card-image">
                {props.image}
            </div>
            <div class="card-content">
                <div class="media">
                    <div class="media-content">
                        <p class="title is-4">{props.title}</p>
                    </div>
                </div>

                <div class="content">
                    <div dangerouslySetInnerHTML={{ __html: props.html_content }} />
                    <br />
                    <div><strong>Nächster Termin: </strong>{props.date}</div>
                    <div><strong>Dauer: </strong>{props.duration}</div>
                    <div><strong>Kosten: </strong>{props.price}</div>
                </div>
                <div>{props.next_date}</div>
                <Link class="button is-primary" to="/kontakt/" state={{ typeRequest: "nähkurs", typeCourse: props.title }}>Anmelden</Link>

                <br />
                <div className="tags">
                    <span className="tag is-primary is-light">Anfänger</span>
                    <span className="tag">Fortgeschritten</span>
                </div>
            </div>
        </div>
    )
}

CourseCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string
}

export default CourseCard