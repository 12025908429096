import React from "react"

class CourseForm extends React.Component {
    render() {
        return (
            <form name="Course Form" netlify="true">
                <input name="form-name" value="Course Form" hidden />
                <div class="field">
                    <label class="label">Name</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Text input" name="name" />
                    </div>
                </div>
                <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-primary" type="submit">Anmelden</button>
                    </div>
                    <div class="control">
                        <button class="button is-link is-light">Abbrechen</button>
                    </div>
                </div>
            </form>
        )
    }
}

export default CourseForm