import React from "react"
import PropTypes from "prop-types"
import CourseForm from "../components/courseForm"

const Modal = (props) => {
    if (!props.modalState) {
        return null;
    }
    return (
        <div className="modal is-active">
            <div className="modal-background">
                <div class="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">{props.title}</p>
                        <button className="delete" onClick={props.closeModal} />
                    </header>
                    <section className="modal-card-body">
                        <div className="content">
                            {props.children}
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <a className="button" onClick={props.closeModal}>Cancel</a>
                    </footer>
                </div>
            </div>
        </div>
    );
}

class ModalButton extends React.Component {
    constructor(props) {
        super(props); 
        this.state = { modalState: false };
    }  
    toggleModal = () => {
        this.setState((state) => {
            const newState = !state.modalState;
            return { modalState: newState };
        });
    }
    render() {
        return (
            <div>
                <button className="button is-primary" onClick={this.toggleModal}>Öffnen</button>
                <Modal
                    closeModal={this.toggleModal}
                    modalState={this.state.modalState}
                    title="Example modal title"
                >
                    {this.props.children}
                </Modal>
            </div>
        );
    }
}

Modal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalState: PropTypes.bool.isRequired,
    title: PropTypes.string
}

export default ModalButton